// src/App.js
import React from "react";
import "./App.css";
import NewsFeed from "./NewsFeed";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1 className="text-4xl md:text-6xl font-bold mb-4 pt-4 fisg0n-title">fisg0n Crypto News 👃</h1>
        <hr className="border-gray-300 my-4 w-full h-0.5" />
        <h2 className="text-lg md:text-xl font-semibold mb-4">
          Sniffing out the most relevant crypto news from the{' '}
          <a
            href="https://cryptopanic.com"
            target="_blank"
            rel="noopener noreferrer"
            className="gradient-link-white"
          >
            CryptoPanic
          </a>
           aggregator.
        </h2>
        <h3 className="text-sm md:text-base font-semibold mb-4">
          With ❤, by{' '}
          <a
            href="https://eliecer.bio"
            target="_blank"
            rel="noopener noreferrer"
            className="gradient-link-white"
          >
            Para.
          </a>
        </h3>
      </header>
      <main>
        <NewsFeed />
      </main>
    </div>
  );
}

export default App;

