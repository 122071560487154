import React, { useState, useEffect } from "react";
import { collection, getDocs, query, where, orderBy, limit } from "firebase/firestore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSmile, faFrown } from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format, parseISO } from "date-fns";
import db from "./firebaseConfig";  

const NewsFeed = () => {
  const [positiveNews, setPositiveNews] = useState([]);
  const [negativeNews, setNegativeNews] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);

  const startOfDay = (date) => {
    const newDate = new Date(date);
    newDate.setHours(0, 0, 0, 0);
    return newDate;
  };

  const endOfDay = (date) => {
    const newDate = new Date(date);
    newDate.setHours(23, 59, 59, 999);
    return newDate;
  };

  useEffect(() => {
    const fetchNews = async (category) => {
      const newsCollection = collection(db, "noticias", category, "items");
      const q = selectedDate
        ? query(
            newsCollection,
            where("Timestamp", ">=", startOfDay(selectedDate).toISOString()),
            where("Timestamp", "<=", endOfDay(selectedDate).toISOString()),
            orderBy("Timestamp", "desc"),
            limit(20)
          )
        : query(
            newsCollection,
            orderBy("Timestamp", "desc"),
            limit(20)
          );
      const snapshot = await getDocs(q);
      const newsList = [];

      snapshot.forEach((doc) => {
        newsList.push({
          id: doc.id,
          data: doc.data(),
        });
      });

      return newsList;
    };

    const fetchData = async () => {
      const positive = await fetchNews("positivas");
      const negative = await fetchNews("negativas");

      setPositiveNews(positive);
      setNegativeNews(negative);
    };

    fetchData();
  }, [selectedDate]);

  const handleNewsClick = (url) => {
    window.open(url, "_blank");
  };

  const renderNewsList = (newsList) => (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
      {newsList.map((item) => (
        <div key={item.id} className="border border-gray-300 rounded p-4">
          <h3
            className="text-base md:text-lg font-semibold cursor-pointer"
            onClick={() => handleNewsClick(item.data["Enlace URL a la noticia"])}
          >
            {item.data["Título de la noticia"]}
          </h3>
          <p className="text-sm text-gray-500">{format(parseISO(item.data["Timestamp"]), "dd/MM/yyyy")}</p>
          <div className="flex mt-2">
            <div className="mr-2 flex items-center">
              <FontAwesomeIcon icon={faSmile} className="text-yellow-500" />
              <span className="ml-1">{item.data.Valoraciones["N positivas"]}</span>
            </div>
            <div className="flex items-center">
              <FontAwesomeIcon icon={faFrown} className="text-red-500" />
              <span className="ml-1">{item.data.Valoraciones["N negativas"]}</span>
            </div>
          </div>
        </div>
      ))}
      {newsList.length === 0 && <p className="text-gray-500">No news at this date 🤨 Try with another one</p>}
    </div>
  );

  return (
    <div className="news-feed p-4 md:p-8">
      <div className="date-picker-container relative mb-8">
        <div className="flex justify-center mb-2">
          <label htmlFor="date-picker" className="text-lg font-semibold mr-4">Filter by date: </label>
          <div className="date-picker-container mobile-margin">
            <DatePicker
              id="date-picker"
              selected={selectedDate}
              onChange={(date) => setSelectedDate(date)}
              dateFormat="yyyy-MM-dd"
              isClearable
            />
          </div>
        </div>
        {positiveNews.length > 0 && (
          <h2 className="text-xl md:text-2xl font-semibold mb-4">Positive 👍</h2>
        )}
      </div>
      {positiveNews.length > 0 && (
        <div className="mb-8">
          {renderNewsList(positiveNews)}
        </div>
      )}
      {negativeNews.length > 0 && (
        <div>
          <h2 className="text-xl md:text-2xl font-semibold mb-4">Negative 👎</h2>
          {renderNewsList(negativeNews)}
        </div>
      )}
    </div>
  );
};

export default NewsFeed;
